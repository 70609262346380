<template>
	<p class="" style="padding: 20px 0; text-align: center">
		深圳市优未来科技有限公司
	</p>
</template>

<script>
export default {
	name: 'Footer'
}
</script>
